import React, { type ReactElement, useRef, useState } from 'react';
import SmileWord from './assets/smile_word.png';
import styled from 'styled-components';
import axios from 'axios';
import LoadingCat from './assets/loading-cat.gif';

const App = (): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [sound, setSound] = useState<string>('');
  const [meaning, setMeaning] = useState<string>('');
  const [meaningKo, setMeaningKo] = useState<string>('');
  const [sentences, setSentences] = useState<string>('');
  const [synonym, setSynonym] = useState<string>('');
  const [betterSentences, setBetterSentences] = useState<string>('');
  const [showCover, setShowCover] = useState<boolean>(false);

  const keyDown = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (e.key === 'Enter') {
      setSound('');
      setMeaning('');
      setSentences('');
      setBetterSentences('');

      const word = inputRef?.current?.value ?? '';

      setShowCover(true);
      if (word.length > 15) {
        const { data } = await axios.post(
          'https://1du8v8t9ck.execute-api.ap-northeast-2.amazonaws.com/dev/better-sentence',
          { sentence: word, key: 'abc!23' },
        );

        setBetterSentences(data.results?.sentenceList ?? '');
      } else {
        const { data } = await axios.post(
          'https://1du8v8t9ck.execute-api.ap-northeast-2.amazonaws.com/dev/sentences',
          { word, key: 'abc!23' },
        );

        setSound(data.results?.sound ?? '');
        setMeaning(data.results?.meaning ?? '');
        setMeaningKo(data.results?.meaningKo ?? '');
        setSentences(data.results?.sentences ?? '');
        setSynonym(data.results?.synonym ?? '');
      }
      setShowCover(false);
    }
  };

  return (
    <Container>
      <div style={{ position: 'relative' }}>
        <WordMonster src={SmileWord} />
        <WordInput onKeyDown={keyDown} ref={inputRef} />
        <SentenceArea>{`${betterSentences}${sound}\n\n${sentences}\n\n\n${meaning}\n\n\n${meaningKo}\n\n\n${synonym}`}</SentenceArea>
      </div>
      {showCover && (
        <Cover>
          <Loading src={LoadingCat} />
        </Cover>
      )}
    </Container>
  );
};

export default App;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const WordMonster = styled.img`
  width: 500px;
  margin: auto;
`;

const WordInput = styled.input`
  height: 30px;
  width: 200px;
  font-size: 20px;
  position: absolute;
  top: 470px;
  left: 146px;
`;

const SentenceArea = styled.div`
  width: 200px;
  height: 300px;
  position: absolute;
  top: 570px;
  left: 146px;
  white-space: pre-line;
  word-break: break-word;
  overflow-y: auto;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(117 190 218 / 0.5);
  z-index: 1000;
`;

const Loading = styled.img`
  width: 100%;
  max-width: 500px;
`;
